import React, { useEffect, useRef } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/Logo.svg";
import logo2 from "../images/Logo2.svg";
import { HeaderData } from "../Data/HeaderData";
import NavMenu from "./NavMenu";
import humburger from "../images/Humburger.svg";
import cross from "../images/cross.svg";
import { RiMenu3Fill } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { ServiceData } from "../Data/ServiceData";
import AccordianMenu from "./AccordianMenu";


const Header = () => {
  
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="main_container2 ">
        <nav className="py-4 lg:py-0">
            <div className="flex justify-between items-center">
              <div className="mr-7 xl:mr-24">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="lg:flex hidden items-center ">
                <ul className="flex h-full">
                  {HeaderData.slice(0, 6).map((item, i) => {
                    return <NavMenu key={i} item={item} />;
                  })}
                </ul>
              </div>
              <div className="xl:flex items-center hidden">
                <div className="">
                  {HeaderData.slice(-1).map((e, i) => {
                    return (
                      <Link
                        key={i}
                        to={e.path}
                        className="bg-transparent btn1 text-troo-white capitalize py-2 px-5"
                      >
                        {e.title}
                      </Link>
                    );
                  })}
                </div>
                <div className="drawer-content ms-5 ">
                  <label htmlFor="my-drawer-1" className="drawer-button">
                    <img src={humburger} alt="humburger" />
                  </label>
                </div>
              </div>
              <div className="md:block lg:hidden text-troo-white drawer-content">
                <label htmlFor="my-drawer-2">
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </label>
              </div>
            </div>
          </nav>
        </div>
        <div className="drawer-side z-100">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <div className="menu flex flex-col p-4 w-[80%] md:w-80 h-full overflow-scroll  bg-troo-deepblack text-troo-white">
            <div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-[200px] md:w-[200px] xl:w-[250px]"
                  />
                </Link>
              </div>
              <div className="flex w-full flex-col items-start gap-2 lg:gap-6 2xl:gap-8">
                {HeaderData.slice(0, 6).map((e, i) => {
                  return !e?.subNav ? (
                    <Link
                      key={i}
                      to={e.path}
                      className={`py-1 flex items-center capitalize text-lg ${
                        e.title === lastvalue ? "active" : ""
                      }`}
                    >
                      {e.title}
                    </Link>
                  ) : (
                    <AccordianMenu title={e.title} subMenu={e.subNav} />
                  );
                })}
              </div>
              <div className="">
                {HeaderData.slice(-1).map((e, i) => {
                  return (
                    <Link key={i} to={e.path} className="btn1 capitalize">
                      {e.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="drawer drawer-end">
        <input id="my-drawer-1" type="checkbox" className="drawer-toggle" />
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <header className="main_container2  absolute w-full left-[50%] translate-x-[-50%] z-10">
          <div className="top_header md:flex hidden justify-between items-center flex-wrap py-5 border-b border-dashed border-troo-whitefade2 pb-5">
            <div className="flex gap-4 text-troo-white">
              <p>
                Welcome To TRoothemes, We Are Professional Company.
                <span className="text-troo-primary font-bold">
                  Need Any Help ?
                </span>
              </p>
            </div>
            <div className="flex items-center gap-1 text-troo-white">
              <p>We Are on :</p>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaFacebookF />
              </Link>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaInstagram />
              </Link>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaTwitter />
              </Link>
              <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                <FaLinkedinIn />
              </Link>
            </div>
            <div className="flex gap-5 text-troo-white">
              <p>
                Phone: <span className="font-bold">+44 123 456 7890</span>
              </p>
              <p className="px-5 border-l border-troo-whitefade2">
                Email: <span className="font-bold">info@troothemes.com</span>
              </p>
            </div>
          </div>
          <nav className="py-4 lg:py-0">
            <div className="flex justify-between items-center">
              <div className="mr-7 xl:mr-24">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="lg:flex hidden items-center ">
                <ul className="flex h-full">
                  {HeaderData.slice(0, 6).map((item, i) => {
                    return <NavMenu key={i} item={item} />;
                  })}
                </ul>
              </div>
              <div className="hidden xl:flex items-center">
                <div className="">
                  {HeaderData.slice(-1).map((e, i) => {
                    return (
                      <Link
                        key={i}
                        to={e.path}
                        className="bg-transparent btn1 text-troo-white capitalize py-2 px-5"
                      >
                        {e.title}
                      </Link>
                    );
                  })}
                </div>
                <div className="drawer-content ms-5">
                  <label htmlFor="my-drawer-1" className="drawer-button">
                    <img src={humburger} alt="humburger" />
                  </label>
                </div>
              </div>
              <div className="md:block lg:hidden text-troo-white drawer-content">
                <label htmlFor="my-drawer-2">
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </label>
              </div>
            </div>
          </nav>
        </header>
        <div className="drawer-side z-50" >
          <label htmlFor="my-drawer-1" className="drawer-overlay"></label>
          <div className="menu w-96  min-h-full bg-troo-white text-base-content">
            <div className="p-5 bg-troo-white">
              <div className="flex justify-between items-center">
                <img src={logo2} alt="logo" />
                <div className="p-2 flex justify-center items-center w-10 h-10 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] ">
                  <img src={cross} alt="cross" />
                </div>
              </div>
              <div className="my-10">
                <h4 className="relative pb-3 mb-10 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-1 before:bg-troo-deepblack">
                  What We Offer
                </h4>
                <div className="flex flex-col gap-5">
                  {ServiceData.map((e, i) => (
                    <Link key={i} className="hover:text-troo-white rounded p-3 bg-troo-fadblue flex justify-between items-center hover:bg-gradient-to-r from-troo-gad1 to-troo-gad2 w-full">
                      {e.name} <BsArrowRight />
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <h4>Contact With US</h4>
                <div className="flex flex-col">
                  <p>For more inquiry: </p>
                  <h5 className="text-troo-gad1">contact@troothems.com</h5>
                </div>
                <div className="flex flex-col">
                  <p>Call Us any Time: </p>
                  <h5 className="text-troo-gad1">+44 132 456 7890</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

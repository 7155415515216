import work1 from "../images/work1.png"
import work2 from "../images/work2.png"
import work3 from "../images/work3.png"
import work4 from "../images/work4.png"
import work5 from "../images/work5.png"
import work6 from "../images/work6.png"
import work7 from "../images/work7.png"
import work8 from "../images/work8.png"

export const WorkData = [
    {
        id : 1,
        img : work1,
        name : "SEO Consultancy",
        para : "Website SEO"
    },
    {
        id : 2,
        img : work2,
        name : "Electronic wallet",
        para : "Website SEO"
    },
    {
        id : 3,
        img : work3,
        name : "E-commerce company",
        para : "Website SEO"
    },
    {
        id : 4,
        img : work4,
        name : "Branding company",
        para : "Website SEO"
    },
    {
        id : 5,
        img : work5,
        name : "SEO Consultancy",
        para : "Website SEO"
    },
    {
        id : 6,
        img : work6,
        name : "Electronic wallet",
        para : "Website SEO"
    },
    {
        id : 7,
        img : work7,
        name : "E-commerce company",
        para : "Website SEO"
    },
    {
        id : 8,
        img : work8,
        name : "Branding company",
        para : "Website SEO"
    },
]
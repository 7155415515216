import service1 from "../images/service1.png"
import service2 from "../images/service2.png"
import service3 from "../images/service3.png"
import service4 from "../images/service4.png"
import service5 from "../images/service5.png"
import service6 from "../images/service6.png"
import service7 from "../images/service7.png"
import service8 from "../images/service8.png"
import work1 from "../images/work1.png"
import work2 from "../images/work2.png"
import work3 from "../images/work3.png"
import work4 from "../images/work4.png"
import work5 from "../images/work5.png"
import work6 from "../images/work6.png"
import work7 from "../images/work7.png"
import work8 from "../images/work8.png"
import serviceicon1 from "../images/serviceIcon1.png"
import serviceicon2 from "../images/serviceIcon2.png"
import serviceicon3 from "../images/serviceIcon3.png"
import serviceicon4 from "../images/serviceIcon4.png"
import serviceicon5 from "../images/serviceIcon5.png"
import serviceicon6 from "../images/serviceIcon6.png"
import serviceicon7 from "../images/serviceIcon7.png"
import serviceicon8 from "../images/serviceIcon8.png"
export const ServiceData = [
    {
        id : 1,
        img : service1,
        img2 :work1,
        icon : serviceicon1,
        name :"SEO Consultancy Services",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
    {
        id : 2,
        img : service2,
        img2 :work2,
        icon : serviceicon2,
        name :"Keyword & Market Research",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
    {
        id : 3,
        img : service3,
        img2 :work3,
        icon : serviceicon3,
        name :"Technical SEO Audit Services",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
    {
        id : 4,
        img : service4,
        img2 :work4,
        icon : serviceicon4,
        name :"Onsite SEO Services",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
    {
        id : 5,
        img : service5,
        img2 :work5,
        icon : serviceicon5,
        name :"Conversion Rate Optimization",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
    {
        id : 6,
        img : service6,
        img2 :work6,
        icon : serviceicon6,
        name :"Schema Markup Strategies",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
    {
        id : 7,
        img : service7,
        img2 :work7,
        icon : serviceicon7,
        name :"Search Engine Optimization",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
    {
        id : 8,
        img : service8,
        img2 :work8,
        icon : serviceicon8,
        name :"Digital Public Relations",
        para : "It is a long established fact that a It is a long established fact that a reader we arewill a be distracted by the readable content."  
    },
]
import React from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { Link, createSearchParams } from 'react-router-dom'
import { BlogData } from '../Data/BlogData'

const Blog3 = () => {
  return (
    <div className='bg-troo-fadblue py-12 lg:py-24'>
        <div className='main_container1'>
            <div className='text-center mb-10'>
                <h2>Related Blogs</h2>
            </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {BlogData.slice(0, 3).map((e, i) => (
            <Link className="hover:shadow group"key={i} to={`/our-blogs/blog-details?${createSearchParams({id : e.id})}`}>
              <div className="relative overflow-hidden">
                <img className="w-full transition ease-in-out duration-500 group-hover:scale-110" src={e.img} alt={e.name} />
                <div className="absolute top-5 right-5">
                  <h5 className="bg-gradient-to-r from-troo-gad1 to-troo-gad2 text-troo-white p-2 text-center max-w-[80px]">
                    {e.date}
                  </h5>
                  <h5 className="bg-troo-primary p-2 text-center text-troo-white">
                    {e.year}
                  </h5>
                </div>
              </div>
              <div className=" flex flex-col gap-5 p-6">
                <div className="flex gap-5  ">
                  <div className="p-2 rounded bg-troo-fadblue flex items-center gap-2">
                    {e.pIcon}
                    {e.pname}
                  </div>
                  <div className="p-2 rounded bg-troo-fadblue flex items-center gap-2">
                    {e.dptIcon}
                    {e.dept}
                  </div>
                </div>
                <h4 className="group-hover:text-troo-gad1">{e.name}</h4>
                <p className="font-bold flex items-center gap-2">
                  Read Full Blog <FiChevronRight />
                </p>
              </div>
            </Link>
          ))}
        </div>
        </div>
    </div>
  )
}

export default Blog3
import React from "react";
import { Link } from "react-router-dom";

const Highlights = () => {
  return (
    <div className="main_container1  mb-[-200px]">
      <div className="flex flex-col p-3 items-center gap-6 bg-highlight-bg bg-cover bg-no-repeat w-full gradient py-8 lg:py-16">
        <h5 className="text-troo-primary">Get Started Now</h5>
        <h2 className="text-troo-white max-w-[600px] mx-auto text-center">
          Need a successful project? We are ready to work it.
        </h2>
        <div className="flex sm:flex-row flex-col  items-center justify-center gap-5">
          <Link to="/get-a-quote" className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white">
            Free Quote
          </Link>
          <h6 className="border border-dotted p-1 text-troo-primary rounded-[50%]">
            OR
          </h6>
          <p className="text-troo-white">
            Call :
            <span className="text-troo-primary font-bold">
              +44 123 456 7890
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Highlights;

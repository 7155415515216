import React from "react";
import { Link } from "react-router-dom";
import bannerRight from "../images/bannerRight.png";

const Banner = () => {
  return (
    <div className=" top-0 w-full">
      <div className="bg-hero-banner bg-no-repeat bg-cover gradient w-ful h-full py-20 ">
        <div className="main_container1 py-20 lg:py-36">
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 text-troo-white ">
            <div className="lg:pt-16">
              <h4 className="mb-5 pt-7 relative before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-primary after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-primary">We Are Number #1 SEO Agency</h4>
              <h1 className="max-w-[950px] m-auto mb-5">
                SEO is Not Optional, it’s Mandatory for Every Business.
              </h1>
              <p className="mb-10 max-w-[600px]">
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                Lorem Ipsum is not simply random text. It has roots 45 BC,
                making it over 2000 years old.
              </p>
              <div className="flex-col inline-flex sm:flex-row  gap-5">
                <Link to="/our-services" className="btn1 bg-troo-white text-troo-deepblack">
                  View Our Services
                </Link>
                <Link to="/contact-us" className="btn1 text-troo-white">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="">
              <img className="w-full h-full" src={bannerRight} alt="bannerright" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;

import React from "react";
import { FaqData } from "../Data/FaqData";
import Accordian from "./Accordian";
import { BiPhone } from "react-icons/bi";
import { LuMail } from "react-icons/lu";

const FAQAccordian2 = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="lg:flex gap-6 py-10">
          <div className="flex flex-col gap-5 items-start">
            {FaqData.slice(0, 9).map((data, i) => {
              return <Accordian key={i} title={data.name} desc={data.para} />;
            })}
          </div>
          <div className="flex flex-col gap-5 items-start">
            {FaqData.slice(9, 18).map((data, i) => {
              return <Accordian key={i} title={data.name} desc={data.para} />;
            })}
          </div>
        </div>
        <div className="text-center py-6">
          <div className="inline-block relative before:content-[''] before:absolute before:w-20 before:h-20 before:left-[-25px] before:top-[-25px] before:bg-troo-fadblue before:z-[-1] before:rounded-[50%]">
            <h3 className="font-normal  ">Still you have a questions?</h3>
            <h3 className="text-troo-gad1">Contact with us</h3>
          </div>
        </div>
        <div className="text-center">
          <div className="bg-gradient-to-r from-troo-gad1 to-troo-gad2 p-5 inline-block rounded">
            <div className="flex gap-16">
              <div className="flex gap-3 text-troo-white">
                <BiPhone className="p-1 bg-troo-whitefade1 rounded text-[30px]" />
                <div className="text-left">
                  <h6>Call Us on</h6>
                  <h5>+41 27 486 86 86</h5>
                </div>
              </div>
              <div className="flex gap-3 text-troo-white">
                <LuMail className="p-1 bg-troo-whitefade1 rounded text-[30px]" />
                <div className="text-left">
                  <h6>Email Us on</h6>
                  <h5>info@campmontana.ch</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQAccordian2;

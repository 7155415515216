import React from "react";
import { WorkData } from "../Data/WorkData";
import { Link, createSearchParams } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const Works = () => {
  return (
    <div className="bg-work-bg bg-no-repeat w-full pt-56 pb-12 lg:pb-24">
      <div className="main_container2">
        <div className="text-center mb-12">
          <h4 className="mb-5 pt-7 relative inline-block before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-gad1 after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-gad2">
            Our Recently Work
          </h4>
          <h2 className="mb-8">Our Recent Case Study</h2>
        </div>
        <div className="grid sm:grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 mb-12">
          {WorkData.map((e, i) => (
            <Link className="relative group rounded overflow-hidden" key={i} to={`/recent-work/recent-work-details?${createSearchParams({id : e.id})}`}>
              <div className="relative before:content-[''] before:absolute before:w-full before:h-full group-hover:before:bg-gradient-to-r from-troo-gad1 to-troo-gad2 before:top-0 before:left-0 before:opacity-40 before:rounded">
                <img className="w-full" src={e.img} alt={e.img} />
              </div>
              <div className="absolute text-troo-white bg-troo-deepblack py-10 px-5 left-0 bottom-0 text-left w-[80%] translate-y-[40px] opacity-0 group-hover:opacity-100 group-hover:translate-y-0 duration-500 ">
                <h3 className="mb-5">{e.name}</h3>
                <p>{e.para}</p>
                <div className="absolute transition-all duration-500 block scale-0 group-hover:scale-100  top-[-15px] right-[-15px]">
                <div className="flex group-hover:delay-100 items-center justify-center w-12 h-12  bg-troo-primary text-troo-white rounded-[50%] group-hover:transition-all ">
                  <FaChevronRight />
                </div>
              </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="text-center">
            <Link to="/recent-work" className="btn1 bg-troo-primary hover:bg-gradient-to-r from-troo-gad1 to-troo-gad2 text-troo-white">View all Case Studies</Link>
        </div>
      </div>
    </div>
  );
};

export default Works;

import benifit1 from "../images/benifit1.png"
import benifit2 from "../images/benifit2.png"
import benifit3 from "../images/benifit3.png"
import icon1 from "../images/benifit-icon1.svg"
import icon2 from "../images/benifit-icon2.svg"
import icon3 from "../images/benifit-icon3.svg"

export const BenifitesData = [
    {
        id : 1,
        name : "High Quality SEO Services",
        img : benifit1,
        icon : icon1
    },
    {
        id : 2,
        name : "Skilled Team Member",
        img : benifit2,
        icon : icon2
    },
    {
        id : 3,
        name : "Perfect SEO Solutions",
        img : benifit3,
        icon : icon3
    },
]
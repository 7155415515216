export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
    heading: "About Us",
  },
  {
    title: "our-services",
    path: "#",
    subNav: [
      {
        subTitle: "our-services",
        heading: "What We Do",
        path: "/our-services",
      },
      {
        subTitle: "services details",
        heading: "SEO Consultancy Services",
        path: "/our-services/services-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "our team",
        heading: "Our Awesome Team Member",
        path: "/our-team",
      },
      {
        subTitle: "our team details",
        heading: "Team Detail Page",
        path: "/our-team/our-team-details",
      },
      {
        subTitle: "recent work",
        heading: "Our Awesome Team Member",
        path: "/recent-work",
      },
      {
        subTitle: "recent work details",
        heading: "Team Detail Page",
        path: "/recent-work/recent-work-details",
      },
      {
        subTitle: "testimonial",
        heading: "Awesome Clients Feedback",
        path: "/testimonial",
      },
      {
        subTitle: "pricing plan",
        heading: "Affordable Price Plan",
        path: "/pricing-plan",
      },
   
      {
        subTitle: "faq",
        heading: "Frequently Asked Questions ",
        path: "/faq",
      },
      {
        subTitle: "error 404",
        path: "/error-404",
      },
      {
        subTitle: "coming soon",
        path: "/coming-soon",
      },
    ],
  },
  {
    title: " Our Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        heading: "News, Tips & Guides",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        heading: "Blog Detail Page",
        path: "/our-blogs/blog-details",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
    heading: "Get in Touch",
  },
  {
    title: "get a quote",
    heading: "Book an Appointment",
    path: "/get-a-quote",
  },
];

import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import mail from "../images/aboutMail.svg";
import img2 from "../images/abtImg-2.jpg";
import img1 from "../images/abtImg1.jpg";
import { FiThumbsUp } from "react-icons/fi";

const Abouts = () => {
  return (
    <div className="bg-troo-white py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="mt-5">
            <h4 className="mb-5 pt-7 relative before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-primary after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-primary">
            About Our Agency
            </h4>
            <h2 className="mb-8">We’re an Expert Search Optimization Agency</h2>
            <h3 className="mb-8">
              It is a long established fact that a reader will be distracted by
              the readable content.
            </h3>
            <p className="mb-8 max-w-[600px]">
              It is a long established fact that a reader we arewill be
              distracted by the readable content of a page when looking at its
              layout. The point of using Lorem Ipsum is that it has a The point
              of using more-or-less normal distribution of letters, as opposed
              to using 'Content here.
            </p>
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-2 mb-16">
              <div className="flex flex-col gap-5">
                <div className="flex gap-4">
                  <div className="p-1 text-lg flex justify-center items-center w-7 h-7 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white ">
                    <BsCheckLg />
                  </div>
                  <h4 className="font-normal font-Manrope">
                    Every lorem ipsum dollr.
                  </h4>
                </div>
                <div className="flex gap-4">
                  <div className="p-1 text-lg flex justify-center items-center w-7 h-7 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white ">
                    <BsCheckLg />
                  </div>
                  <h4 className="font-normal font-Manrope">
                    Using powerful lorem ipsum
                  </h4>
                </div>
                <div className="flex gap-4">
                  <div className="p-1 text-lg flex justify-center items-center w-7 h-7 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white ">
                    <BsCheckLg />
                  </div>
                  <h4 className="font-normal font-Manrope">
                    Lorem ipsum dollr sit.
                  </h4>
                </div>
              </div>
              <div className="flex flex-col gap-5">
                <div className="flex gap-4">
                  <div className="p-1 text-lg flex justify-center items-center w-7 h-7 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white ">
                    <BsCheckLg />
                  </div>
                  <h4 className="font-normal font-Manrope">
                    Every lorem ipsum dollr.
                  </h4>
                </div>
                <div className="flex gap-4">
                  <div className="p-1 text-lg flex justify-center items-center w-7 h-7 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white ">
                    <BsCheckLg />
                  </div>
                  <h4 className="font-normal font-Manrope">
                    Using powerful lorem ipsum
                  </h4>
                </div>
                <div className="flex gap-4">
                  <div className="p-1 text-lg flex justify-center items-center w-7 h-7 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white ">
                    <BsCheckLg />
                  </div>
                  <h4 className="font-normal font-Manrope">
                    Lorem ipsum dollr sit.
                  </h4>
                </div>
              </div>
            </div>
            <div className="flex flex-col xl:flex-row xl:items-center gap-12">
              <div>
                <Link className="btn1 bg-gradient-to-r from-troo-gad1 to-troo-gad2 text-troo-white" to="/about-us"> 
                  Read More
                </Link>
              </div>
              <div className="flex sm:flex-row flex-col items-start  sm:items-center gap-5">
                <img src={mail} alt="mail" />
                <div>
                  <p>Let’s talk with us</p>
                  <h3>info@troothemes.com</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="grid  grid-cols-2 gap-5">
            <div className="flex flex-col gap-5 ">
              <div className="flex sm:flex-row flex-col  gap-3 lg:gap-7 py-5 lg:py-8 px-2 lg:px-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded">
                <div className="">
                  <FiThumbsUp className="lg:p-5 p-1 bg-troo-white text-troo-gad2 lg:w-20 w-8 h-8 lg:h-20 flex justify-center items-center rounded-[50%] " />
                </div>
                <div className="flex flex-wrap justify-center items-center text-troo-white">
                  <h3>25+ Year of Experience</h3>
                </div>
              </div>
              <div className="gradientlight h-full">
                <img className="w-full h-full rounded " src={img1} alt="img1" />
              </div>
            </div>
            <div className="gradientlight h-full">
              <img className="w-full h-full rounded " src={img2} alt="img2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abouts;

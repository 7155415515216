import React from "react";
import Highlights from "./Highlights";
import FAQAccordian from "./FAQAccordian";

const FAQs = () => {
  return (
    <div className="pb-12 bg-troo-fadblue z-[1] relative ">
      <FAQAccordian />
      <Highlights />
    </div>
  );
};

export default FAQs;

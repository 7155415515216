import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BlogDetails from '../../Components/BlogDetails'
import Blog3 from '../../Components/Blog3'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogDetailsPage = () => {
  useDocumentTitle("TRoo SEO Agency | Blog Details")
  return (
    <Layout>
    <BannerGlobal />
    <BlogDetails/>
    <Blog3/>
  </Layout>
  )
}

export default BlogDetailsPage
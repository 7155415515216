import team1 from "../images/team1.png";
import team2 from "../images/team2.png";
import team3 from "../images/team3.png";
import team4 from "../images/team4.png";
import team5 from "../images/team5.png";
import team6 from "../images/team6.png";
import team7 from "../images/team7.png";
import team8 from "../images/team8.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";

export const TeamData = [
  {
    id: 1,
    img: team1,
    name: "Andrew Avery",
    sub: "Founder & SEO",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 2,
    img: team2,
    name: "Benjamin Burgess",
    sub: "HR Managment",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 3,
    img: team3,
    name: "Daisy Stracke",
    sub: "Project Manager",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 4,
    img: team4,
    name: "Hortense Lazuardi",
    sub: "Digital Markering Expert",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 5,
    img: team5,
    name: "Kimberly Greene",
    sub: "SEO Team Leader",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 6,
    img: team6,
    name: "Henri Novitasari",
    sub: "SEO Director",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 7,
    img: team7,
    name: "Sarah Albert",
    sub: "SEO Team Leader",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
  {
    id: 8,
    img: team8,
    name: "David Cooper",
    sub: "SEO Director",
    social: [
      {
        icon: <FaFacebookF />,
        path: "",
      },
      {
        icon: <FaInstagram />,
        path: "",
      },
      {
        icon: <FaTwitter />,
        path: "",
      },
      {
        icon: <FaLinkedinIn />,
        path: "",
      },
    ],
  },
];

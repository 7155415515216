import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Maps from '../Components/Maps'
import Contacts from '../Components/Contacts'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactsPage = () => {
  useDocumentTitle("TRoo SEO Agency | Contact us")
  return (
    <Layout>
    <BannerGlobal />
    <Maps/>
    <Contacts/>
  </Layout>
  )
}

export default ContactsPage
import React from "react";
import { Link, createSearchParams } from "react-router-dom";
import { ServiceData } from "../Data/ServiceData";
import { FaChevronRight } from "react-icons/fa";

const Services = () => {
  return (
    <div className="bg-service-bg bg-no-repeat w-full gradient py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row justify-between gap-8 lg:items-center mb-20">
          <div className="text-troo-white">
            <h4 className="mb-5 pt-7 relative before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-primary after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-primary">
              What We’re Offering
            </h4>
            <h2 className="max-w-[600px]">
              Services Built Specifically for your Business
            </h2>
          </div>

          <div>
            <Link to="/our-services" className="btn1 text-troo-white">View Our Services</Link>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-[-220px]">
          {ServiceData.slice(0,4).map((e, i) => (
            <Link
              className="bg-troo-white px-5 py-8 rounded benifiteCard group shadow-md"
              key={i}
              to={`/our-services/services-details?${createSearchParams({id : e.id})}`}
            >
              <div className="flex flex-col gap-5">
                <div className="mb-5">
                  <img src={e.img} alt={e.img} />
                </div>
                <h4>{e.name}</h4>
                <p>{e.para}</p>
              </div>
              <div className="absolute top-5 right-5">
                <img src={e.icon} alt={e.icon} />
              </div>
              <div className="absolute transition-all duration-500 block scale-0 group-hover:scale-100  bottom-[-20px] right-10">
                <div className="flex group-hover:delay-100 items-center justify-center w-12 h-12  bg-troo-primary text-troo-white rounded-[50%] group-hover:transition-all ">
                  <FaChevronRight />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;

import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/Logo.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import { IoChatbubblesOutline } from "react-icons/io5";

import { CiLocationOn } from "react-icons/ci";

const Footer = () => {
  return (
    <div className="bg-footer-bg bg-no-repeat bg-cover gradient w-ful h-full pt-12 lg:pt-28 pb-6">
      <div className="main_container1">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-7 w-full lg:gap-12 mb-12">
          <div className="flex flex-col md:flex-row justify-between gap-10 text-troo-white">
            <div className="flex flex-col gap-5">
              <Link>
                <img src={logo} alt="logo" />
              </Link>
              <p className="md:w-[400px] w-full">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard.
              </p>
              <div className="flex items-center gap-1 text-troo-white mb-8">
                <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                  <FaFacebookF />
                </Link>
                <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                  <FaInstagram />
                </Link>
                <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                  <FaTwitter />
                </Link>
                <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
                  <FaLinkedinIn />
                </Link>
              </div>
              <h4>Monday to Saturday: 10: 00 AM to 06:00 PM</h4>
              <h4>Sunay: Closed</h4>
            </div>
            <div>
              <h3 className="mb-8">Our Services</h3>
              <nav className="flex flex-col gap-5">
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> SEO Consultancy
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Onsite SEO
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Penalty Recovery
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Link Building
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Real Time And Data
                </Link>
              </nav>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-8 justify-around text-troo-white">
            <div>
              <h3 className="mb-8">Useful Links</h3>
              <nav className="flex flex-col gap-5">
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Home
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> About
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Our Services
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Our Team
                </Link>
                <Link className="flex gap-3 items-center">
                  <BsArrowRight /> Blogs
                </Link>
              </nav>
            </div>
            <div>
              <h3 className="mb-8">Contact With Us</h3>
              <nav className="flex flex-col gap-4">
                <div className="flex gap-3 ">
                  <div>
                    <IoChatbubblesOutline className="bg-troo-whitefade1 p-2 rounded-[50%] w-10 h-10 text-troo-primary" />
                  </div>
                  <div>
                    <p className="mb-3">Call for Order</p>
                    <p>+44 123 456 7890</p>
                    <p>contact @troothemes.com</p>
                  </div>
                </div>
                <div className="flex gap-3 ">
                  <div>
                    <CiLocationOn className="bg-troo-whitefade1 p-2 rounded-[50%] w-10 h-10 text-troo-primary" />
                  </div>
                  <div>
                    <p className="mb-3">Our Location</p>
                    <p className="max-w-[200px]">
                      119 Tanglewood Lane Gulfport, MS 39503
                    </p>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="lg:flex justify-between gap-5 text-troo-white">
          <div className="bg-troo-whitefade1 px-7 py-1 rounded">
            <p>Copyright © 2023. troothemes. All rights reserved.</p>
          </div>
          <div className="flex gap-5 bg-troo-whitefade1 px-7 py-1 rounded">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

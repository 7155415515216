import React from "react";
import img404 from "../../images/404img.png";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../Hooks/PageTitle";

const ErrorPage = () => {
  useDocumentTitle("TRoo SEO Agency | Error404");
  return (
    <div className="bg-error-bg w-full bg-no-repeat bg-center py-12 lg:py-44">
      <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <div className="relative before:content-[''] before:w-[1px] before:h-24 before:left-[50%] before:top-0 before:bg-gradient-to-r from-troo-gad1 to-troo-gad2 before:absolute pt-24">
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2>Page Not Found</h2>
          <h3 className="max-w-[800px] text-center m-auto">
            The page you look for does not exist or another error occured please
            kindly refreash or retun to home page.
          </h3>
          <div className="flex-col sm:flex-row flex justify-center gap-5">
            <Link
              to="/"
              className="btn1 hover:bg-gradient-to-r from-troo-gad1 to-troo-gad2 hover:text-troo-white"
            >
              Go to Hoempage
            </Link>
            <Link className="btn1">Go Back</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Blog2 from '../../Components/Blog2'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogsPage = () => {
  useDocumentTitle("TRoo SEO Agency | Blogs")
  return (
    <Layout>
    <BannerGlobal/>
    <Blog2/>
  </Layout>
  )
}

export default BlogsPage
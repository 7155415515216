import React, { useEffect, useState } from "react";
import teamDetails from "../images/teamDetails.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [sub,setSub] = useState("")
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");
  useEffect(() => {
    const filData = TeamData.find((data) => data?.id == id);
    setPicture(filData?.img);
    setHeading(filData?.name);
    setSub(filData?.sub)
  }, [id]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-20 mb-10">
          <div className=" ">
            <img
              className="w-full lg:max-w-[600px] lg:max-h-[600px] "
              src={picture || teamDetails}
              alt={teamDetails}
            />
          </div>
          <div className="flex flex-col gap-4">
            <h3 className="max-w-[500px]">
              I am Ready to help My Clients to World Best Services
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus, ipsum
              varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices
              dui diam eu urna. Nam nec cursus velit. Praesent a sem nunc. Ut
              sit amet velit sed sem sodales cursus quis at nibh. ipsum varius
              suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui
              diam.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,ipsum
              varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices
              dui diam eu urna. Nam nec cursus velit. Praesent.
            </p>
            <hr className="my-10" />
            <div>
              <h4>{heading || "Andrew Avery"} </h4>
              <p>{sub || "CEO and Founder"}</p>
            </div>
            <div className="lg:flex justify-between gap-5">
              <p>
                Phone No: <span className="font-bold">+44 123 456 7890</span>
              </p>
              <p>
                Email:{" "}
                <span className="font-bold">jennijohn@troothemes.com</span>
              </p>
            </div>
            <div>
              <div className="inline-block bg-troo-deepblack p-6">
                <div className="flex gap-5 text-troo-white">
                  <FaFacebookF />
                  <FaInstagram />
                  <FaLinkedinIn />
                  <FaTwitter />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-20">
          <div className="flex flex-col gap-4 pt-8">
            <h2>My Experience</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus, ipsum
              varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices
              dui diam eu urna. Nam nec cursus velit. Praesent a sem nunc. Ut
              sit amet velit sed sem sodales cursus quis at nibh. ipsum varius
              suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui
              diam.
            </p>
          </div>
          <div className="flex flex-col justify-around">
            <div className="flex flex-col gap-3 mb-5">
              <h4>Experience</h4>
              <div className="h-1 w-full bg-troo-fadblue">
                <div className="h-1 w-[90%] bg-gradient-to-r from-troo-gad1 to-troo-gad2"></div>
              </div>
            </div>
            <div className="flex flex-col gap-4 mb-5">
              <h4>Complited Projects</h4>
              <div className="h-1 w-full bg-troo-fadblue">
                <div className="h-1 w-[70%] bg-gradient-to-r from-troo-gad1 to-troo-gad2"></div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <h4>Client’s Setisfied</h4>
              <div className="h-1 w-full bg-troo-fadblue">
                <div className="h-1 w-[80%] bg-gradient-to-r from-troo-gad1 to-troo-gad2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;

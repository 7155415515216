
import team1 from "../images/team1.png";
import team2 from "../images/team2.png";
import team3 from "../images/team5.png";
import team4 from "../images/team4.png";
import team5 from "../images/team5.png";
import team6 from "../images/team6.png";
import team7 from "../images/team7.png";
import team8 from "../images/team8.png";

import { FaStar } from "react-icons/fa";

export const FeedbackData = [
  {
    id: 1,
    name: "Ryan Nader",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team1,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
  {
    id: 2,
    name: "Lon Gerhold",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team2,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
  {
    id: 3,
    name: "Fermin Corwin",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team3,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
  {
    id: 4,
    name: "Roger Stanton",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team4,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
  {
    id: 5,
    name: "Rudy Holmes",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team5,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
  {
    id: 6,
    name: "Tanner Barker",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team6,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
  {
    id: 7,
    name: "Sarah Parry",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team7,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
  {
    id: 8,
    name: "Joselyn Garcia",
    para: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from Lorem Ipsum.Contrary to popula.",
    link : "www.dummyurl.com",  
    img: team8,
    star: (
      <>
        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
      </>
    ),
  },
];

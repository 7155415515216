import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import Teams2 from "../../Components/Teams2";
import useDocumentTitle from "../../Hooks/PageTitle";

const TeamPage = () => {
  useDocumentTitle("TRoo SEO Agency | Teams")
  return (
    <Layout>
      <BannerGlobal />
      <Teams2/>
    </Layout>
  );
};

export default TeamPage;

import React from "react";
import { FeedbackData } from "../Data/FeedbackData";
import { Link } from "react-router-dom";

const Feedbacks = () => {
  return (
    <div className="bg-feedback-bg gradient bg-no-repeat w-full py-12 lg:py-24 bg-center bg-contain">
      <div className="main_container1">
        <div className="text-center text-troo-white mb-12">
          <h4 className="mb-5 pt-7 relative inline-block before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-gad1 after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-gad2">
            Our Client’s Feedback
          </h4>
          <h2 className="mb-8 max-w-[600px] mx-auto">
            To Learn More Check Out Our Success Stories
          </h2>
        </div>
        <div className="flex flex-col md:flex-row gap-6 xl:gap-14 xl:justify-start justify-center mb-10">
          {FeedbackData.slice(0, 2).map((e, i) => (
                    <Link key={i} className="flex flex-col lg:flex-row gap-4 lg:gap-8 bg-troo-white rounded  group  p-5">
                    <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-gradient-to-r before:from-troo-gad1 before:to-troo-gad2 before:top-0 before:left-0 before:opacity-40 before:rounded before:transform before:translate-y-full before:transition-all before:ease-in-out before:duration-500 before:z-10 group-hover:before:translate-y-0 overflow-hidden">
                      <img className="w-full lg:-w-[170px] lg:max-h-[200px]" src={e.img} alt={e.name} />
                    </div>
                    <div>
                      <div className="flex sm:flex-row flex-col  justify-between items-start gap-3 sm:items-center mb-4 lg:mb-7">
                        <div>
                          <h4 className="group-hover:text-troo-gad1">{e.name}</h4>
                          <p>{e.link}</p>
                        </div>
                        <div className="flex gap-2 text-troo-primary group-hover:text-troo-gad1">{e.star}</div>
                      </div>
                      <p className=" lg:max-w-[300px] bg-troo-fadblue p-2">{e.para}</p>
                    </div>
                  </Link>
          ))}
        </div>
        <div className="flex flex-col md:flex-row  gap-6 xl:gap-14 xl:justify-end justify-center">
          {FeedbackData.slice(2, 4).map((e, i) => (
         <Link key={i} className="flex flex-col lg:flex-row gap-4 lg:gap-8 bg-troo-white rounded  group  p-5">
         <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-gradient-to-r before:from-troo-gad1 before:to-troo-gad2 before:top-0 before:left-0 before:opacity-40 before:rounded before:transform before:translate-y-full before:transition-all before:ease-in-out before:duration-500 before:z-10 group-hover:before:translate-y-0 overflow-hidden">
           <img className="w-full lg:-w-[170px] lg:max-h-[200px]" src={e.img} alt={e.name} />
         </div>
         <div>
           <div className="flex sm:flex-row flex-col  justify-between items-start gap-3 sm:items-center mb-4 lg:mb-7">
             <div>
               <h4 className="group-hover:text-troo-gad1">{e.name}</h4>
               <p>{e.link}</p>
             </div>
             <div className="flex gap-2 text-troo-primary group-hover:text-troo-gad1">{e.star}</div>
           </div>
           <p className=" lg:max-w-[300px] bg-troo-fadblue p-2">{e.para}</p>
         </div>
       </Link>
          ))}
        </div>
        <div className="text-center bg-transparent mt-12">
          <Link to="/testimonial" className="btn1  text-troo-white">View all Review</Link>
        </div>
      </div>
    </div>
  );
};

export default Feedbacks;

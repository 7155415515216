import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";
import { TeamData } from "../Data/TeamData";
import { HeaderData } from "../Data/HeaderData";
import { WorkData } from "../Data/WorkData";
import { ServiceData } from "../Data/ServiceData";

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("blog-details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading(bData.name);
      }
      if (pathName.includes("services-details")) {
        const sData = ServiceData.find((e) => e.id == id);
        setHeading(sData.name);
      }
      if (pathName.includes("recent-work-details")) {
        const wData = WorkData.find((e) => e.id == id);
        setHeading(wData.name);
      }
      if (pathName.includes("team-details")) {
        const tData = TeamData.find((e) => e.id == id);
        setHeading(tData.name);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.heading);
      }
    }
  }, []);
  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className="top-0 w-full">
      <div className="bg-banner-global bg-no-repeat bg-cover gradient w-ful h-full py-12 lg:py-24 relative">
        <div className="main_container py-12 lg:py-24">
          <div className="md:pt-24 flex flex-col justify-center items-center">
            <div className=" bg-troo-whitefade1 py-3 px-5 md:px-8 rounded shadow-sm">
              <h2 className="text-troo-white text-center">{heading}</h2>
              <ul className="flex flex-wrap gap-1 justify-center  px-5 py-3 text-troo-white">
                <Link to="/">Home</Link>
                <span>/</span>
                {breadcrumbs.map((breadcrumb, index) => (
                  <>
                    <li
                      className={` capitalize text-troo-white ${
                        index === breadcrumbs.length - 1
                          ? " text-troo-primary"
                          : ""
                      }`}
                    >
                      <Link
                        key={index}
                        to={breadcrumb.path}
                        className={
                          breadcrumb.path === "/faq" ? "uppercase" : ""
                        }
                      >
                        {breadcrumb.title.replace(/-/g, " ")}
                      </Link>
                    </li>
                    {index !== breadcrumbs?.length - 1 && <span>/</span>}
                  </>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerGlobal;

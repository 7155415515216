import { Link, useLocation } from "react-router-dom";
import { HiOutlineChevronDown } from "react-icons/hi";

const NavMenu = ({ item }) => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");
  let filt = item.subNav?.find((s) => s.subTitle === lastvalue);
  console.log(item.title);
  console.log(arr);
  return (
    <li className="nav_item h-full">
      {!item?.subNav ? (
        <Link
          to={item.path}
          className={`nav_link py-8 ${
            item.path === location.pathname ? "active " : ""
          }`}
        >
          {item.title}
        </Link>
      ) : (
        <div class="dropdown dropdown-hover">
          <Link
            to={item.path}
            className={`nav_link py-8 ${
              item.title === lastvalue || filt ? "active " : ""
            }`}
          >
            {item.title}
            <HiOutlineChevronDown className="ml-1" />
          </Link>
          {
            <ul className="dropdown-content z-[1] menu p-2 shadow text-troo-deepblack font-medium font-Montserrat bg-troo-white  w-52">
              {item.subNav.map((e, i) => {
                return (
                  <li key={i}
                    className={`text-left font-semibold font-Montserrat hover:bg-troo-whitefade hover:text-troo-white ${
                      e.subTitle === lastvalue ? "active" : " "
                    }`}
                  >
                    <Link to={e.path}>{e.subTitle} </Link>
                  </li>
                );
              })}
            </ul>
          }
        </div>
      )}
    </li>
  );
};

export default NavMenu;

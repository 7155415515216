export const FaqData = [
  {
    id: 1,
    name: "Which SEO techniques are popular?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 2,
    name: "What is SEO Backlinks?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 3,
    name: "What is link Building in SEO?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 4,
    name: "How Long Will It Take to See the Effects of SEO?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 5,
    name: "How Much Do SEO Services Costs?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 6,
    name: "How Can Find Best Local SEO Marketing Company?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 7,
    name: "How Long Does It Take to See Results From SEO?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 8,
    name: "Why Do I Need SEO Services?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 9,
    name: "What Are SEO Best Practices?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 10,
    name: "How Long Will It Take to See the Effects of SEO?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 11,
    name: "Where Can I Learn How to Do SEO?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 12,
    name: "Why Has My Organic Traffic Dropped?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 13,
    name: "How much does SEO cost?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 14,
    name: "Can't we just buy links?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 15,
    name: "Once start SEO, how long will it take to get rankings?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 16,
    name: "Can you guarantee my rankings with SEO?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 17,
    name: "Should I also do PPC?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
  {
    id: 18,
    name: "How long will it take to get rankings?",
    para: "Lorem Ipsum is simply dummy text of and the printing and typesetting industry. Lorem Ipsum and has been the industry's standard dummy text ever since  the printing and typesetting industry.",
  },
];

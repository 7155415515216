import React from "react";
import { BenifitesData } from "../Data/BenifitesData";
import { Link } from "react-router-dom";

const Benifites = () => {
  return (
    <div className="bg-benifites-home bg-no-repeat w-full bg-left-top py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row  justify-between items-center gap-7 mb-10">
          <div className="w-full">
            <h4 className="mb-5 pt-7 relative before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-primary after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-primary">
              Our Agency Benefits
            </h4>
            <h2>Let’s Work Together</h2>
          </div>
          <p className="lg:max-w-[500px] xl:max-w-[600px]">
            Contrary to popular belief, Lorem Ipsum is not simply random text.
            It has roots in a piece of classical Latin literature from Lorem
            Ipsum is not simply random text. It hasroots 45 BC, making it over
            2000 years old.
          </p>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
          {BenifitesData.map((e, i) => (
            <Link className="benifiteCard">
              <div className="relative flex justify-center ">
                <div className="benifitesImg">
                  <img className="w-full" src={e.img} alt={e.img} />
                </div>
                <div className="absolute text-center bottom-0">
                  <h3 className="max-w-[200px] mx-auto">{e.name}</h3>
                </div>
              </div>
              <div>
                <div className="benifiteIcon">
                  <img className="w-full p-4" src={e.icon} alt={e.icon} />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Benifites;

import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import TeamDetails from '../../Components/TeamDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamDetailsPage = () => {
  useDocumentTitle("TRoo SEO Agency | Teams Details")
  return (
    <Layout>
      <BannerGlobal />
      <TeamDetails/>
    </Layout>
  )
}

export default TeamDetailsPage
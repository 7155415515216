import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import workDetails from "../images/workDetailsImg.png";
import { BsCheckLg } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { WorkData } from "../Data/WorkData";


const WorkDetails = () => {
  const [picture, setPicture] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");
  useEffect(() => {
    const filData = WorkData.find((data) => data?.id == id);
    setPicture(filData?.img);
  }, [id]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-5">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus, ipsum
            varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui
            diam eu urna. Nam nec cursus velit. Praesent a sem nunc. Ut sit amet
            velit sed sem sodales cursus quis at nibh. ipsum varius suscipit
            laoreet.
          </p>
          <div className="w-full grid grid-cols-2 md:grid-cols-4 lg:flex  justify-around">
            <div className=" px-5 py-2">
              <h5>Client</h5>
              <p>Elegent Divi Themses</p>
            </div>
            <div className=" px-5 py-2">
              <h5>Date</h5>
              <p>22 March,, 2023</p>
            </div>
            <div className=" px-5 py-2">
              <h5>Category</h5>
              <p>Website SEO</p>
            </div>
            <div className=" px-5 py-2">
              <h5>Project Head</h5>
              <p>David Cooper</p>
            </div>
            <div className=" px-5 py-2">
              <h5>Link</h5>
              <Link className="text-troo-gad1">Website Link</Link>
            </div>
          </div>
          <h2>Project overview</h2>
          <p>
            There are many variations of passages of lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour or randomised words which don't look even slightly
            believable. Egestas dictum lectus diam suffered alteration in some
            form, by injected humour or randomised words which commodo.
            Marketing tristique nunc faucibus sit tortor commodo aliquet commodo
            quam. Id suspendisse vel in non arcu, interdum quis placerat
            accumsan varius
          </p>
          <p>
            There are many variations of passages of lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour or randomised words which don't look even slightly
            believable. Egestas dictum lectus diam commodo. Marketing tristique
            nunc fThere are many variations of passages of lorem Ipsum
            available, but the majority have suffered alteration in some form,
            by injected humour or randomised words which don't look even
            slightly believable. Egestas dictum lectus diam commodo. Marketing
            tristique nunc faucibus sit tortor commodo aliquet commodo quam. Id
            suspendisse vel in non arcu, interdum quis placerat accumsan
            variusaucibus.
          </p>
          <h2>Project requirement</h2>
          <p>
            There are many variations of passages of lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour or randomised words which don't look even slightly
            believable. Egestas dictum lectus diam commodo. Marketing tristique
            nunc fThere are many variations of passages of lorem Ipsum
            available, but the majority have suffered alteration in some form,
            by injected humour or randomised words which don't look even
            slightly believable..
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div className="sm:flex  items-center lg:gap-24 md:gap:10 w-full">
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Using powerful lorem dollr
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Lorem ipsum dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Using powerful lorem dollr
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Lorem ipsum dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Lorem ipsum abced dollr sit.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Every lorem ipsum dollr.
                </p>
              </div>
            </div>
            <div>
              <img className="w-full max-h-[400px]" src={picture || workDetails} alt={workDetails} />
            </div>
          </div>
          <h2>We Gave them solution</h2>
          <p>
            There are many variations of passages of lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour or randomised words which don't look even slightly
            believable. Egestas dictum lectus diam commodo. Marketing tristique
            nunc fThere are many variations of passages of lorem Ipsum
            available, but the majority have suffered alteration in some form,
            by injected humour or randomised words which don't look even
            slightly believable..
          </p>
          <div className="bg-troo-fadblue flex flex-col sm:flex-row gap-3 items-center justify-between p-3">
            <div className="flex  items-center gap-3">
              <h5>Share This Project on:</h5>
              <FaFacebookF />
              <FaInstagram />
              <FaLinkedinIn />
              <FaTwitter />
            </div>
            <div className="flex gap-3">
              <h5>Tags:</h5>
              <p>Website SEO, Digital Marketing</p>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default WorkDetails;


export const PricingData = [
    {
        id :1,
        name : "Starter Plan",
        price : "$19.99",
        sub : "Chooses to enjoy a no annoying.",
        highlights:[
            {
                heading : "Two Free Website",
                className : "flex items-center gap-4"
            },
            {
                heading : "100 Page Seo Optimization",
                className : "flex items-center gap-4"
            },
            {
                heading : "Unlimited Analyse Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : " Unlimited Domain Name Free",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "To Custimzed Your Site",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "24/7 Support Team",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :2,
        name : "Premium Plan",
        price : "$49.99",
        sub : "Chooses to enjoy a no annoying.",
        highlights:[
            {
                heading : "Two Free Website",
                className : "flex items-center gap-4"
            },
            {
                heading : "100 Page Seo Optimization",
                className : "flex items-center gap-4"
            },
            {
                heading : "Unlimited Analyse Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : " Unlimited Domain Name Free",
                className : "flex items-center gap-4"
            },
            {
                heading : "To Custimzed Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : "24/7 Support Team",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :3,
        name : "Ultimate Plan",
        price : "$79.99",
        sub : "Chooses to enjoy a no annoying.",
        highlights:[
            {
                heading : "Two Free Website",
                className : "flex items-center gap-4"
            },
            {
                heading : "100 Page Seo Optimization",
                className : "flex items-center gap-4"
            },
            {
                heading : "Unlimited Analyse Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : " Unlimited Domain Name Free",
                className : "flex items-center gap-4"
            },
            {
                heading : "To Custimzed Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : "24/7 Support Team",
                className : "flex items-center gap-4"
            },
        ]
    },
    {
        id :4,
        name : "Corporate Plan",
        price : "$19.99",
        sub : "Chooses to enjoy a no annoying.",
        highlights:[
            {
                heading : "Two Free Website",
                className : "flex items-center gap-4"
            },
            {
                heading : "100 Page Seo Optimization",
                className : "flex items-center gap-4"
            },
            {
                heading : "Unlimited Analyse Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : " Unlimited Domain Name Free",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "To Custimzed Your Site",
                className : "flex items-center gap-4 opacity-20"
            },
            {
                heading : "24/7 Support Team",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :5,
        name : "Super Plan",
        price : "$19.99",
        sub : "Chooses to enjoy a no annoying.",
        highlights:[
            {
                heading : "Two Free Website",
                className : "flex items-center gap-4"
            },
            {
                heading : "100 Page Seo Optimization",
                className : "flex items-center gap-4"
            },
            {
                heading : "Unlimited Analyse Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : " Unlimited Domain Name Free",
                className : "flex items-center gap-4"
            },
            {
                heading : "To Custimzed Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : "24/7 Support Team",
                className : "flex items-center gap-4 opacity-20"
            },
        ]
    },
    {
        id :6,
        name : "Ultimate Plan",
        price : "$19.99",
        sub : "Chooses to enjoy a no annoying.",
        highlights:[
            {
                heading : "Two Free Website",
                className : "flex items-center gap-4"
            },
            {
                heading : "100 Page Seo Optimization",
                className : "flex items-center gap-4"
            },
            {
                heading : "Unlimited Analyse Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : " Unlimited Domain Name Free",
                className : "flex items-center gap-4"
            },
            {
                heading : "To Custimzed Your Site",
                className : "flex items-center gap-4"
            },
            {
                heading : "24/7 Support Team",
                className : "flex items-center gap-4"
            },
        ]
    },
]
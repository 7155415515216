import React from "react";
import { BiPhone } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import { LuMail } from "react-icons/lu";
import { Link } from "react-router-dom";

const Contacts = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1 bg-troo-fadblue py-10">
        <h2 className="max-w-[700px] mx-auto text-center my-10">
          Need Any Help? Don’t Hesitate To Contact With Us
        </h2>
        <div className="bg-troo-white lg:p-12 p-4 mb-10">
          <div className="flex flex-col md:flex-row  justify-between gap-4 xl:gap-16">
            <div className="flex gap-3 bg-troo-fadblue py-4 xl:py-9 px-4 xl:px-12 group hover:shadow">
              <BiPhone className="p-1 bg-troo-whitefade1 rounded text-[30px]" />
              <div className="text-left">
                <h4 className="mb-3 group-hover:text-troo-gad1">Call Us on</h4>
                <p>+41 27 486 86 86</p>
                <p>+44 123 456 7890</p>
              </div>
            </div>
            <div className="flex gap-3 bg-troo-fadblue py-4 xl:py-9 px-4 xl:px-12 group hover:shadow">
              <LuMail className="p-1 bg-troo-whitefade1 rounded text-[30px]" />
              <div className="text-left">
                <h4 className="mb-3 group-hover:text-troo-gad1">Email Us on:</h4>
                <p>info@troothemes.com</p>
                <p>Contact@troothemes.com</p>
              </div>
            </div>
            <div className="flex gap-3 bg-troo-fadblue py-4 xl:py-9 px-4 xl:px-12 group hover:shadow">
              <GrLocation className="p-1 bg-troo-whitefade1 rounded text-[30px]" />
              <div className="text-left ">
                <h4 className="mb-3 group-hover:text-troo-gad1">Visit Us:</h4>
                <p>Ewingar Road Lionsville,</p>
                <p>New South Wales, 2460 Aus</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-troo-white lg:p-12 p-6">
        <form className=" w-full">
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <div className="w-full">
                    <p className="mb-3">First Name</p>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="p-5 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Last Name</p>
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="p-5 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Enter Your Email</p>
                    <input
                      type="email"
                      placeholder="Email"
                      className="p-5 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Enter Your Phone</p>
                    <input
                      type="text"
                      placeholder="Enter Your Phone"
                      className="p-5 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <p className="mb-3">Write Comment</p>
                  <textarea
                    placeholder="White something to me"
                    className="p-5 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                    rows={8}
                  />
                </div>
                <div>
                  <Link className="btn1 bg-gradient-to-r from-troo-gad1 to-troo-gad2 text-troo-white  ">
                  Submit Now
                  </Link>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  );
};

export default Contacts;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import ServicePgae from "./Routes/Service/ServicePgae";
import ServiceDetailsPage from "./Routes/Service/ServiceDetailsPage";
import TeamPage from "./Routes/Pages/TeamPage";
import TeamDetailsPage from "./Routes/Pages/TeamDetailsPage";
import WorksPage from "./Routes/Pages/WorksPage";
import WorkDetailsPage from "./Routes/Pages/WorkDetailsPage";
import FeedBackPage from "./Routes/Pages/FeedBackPage";
import FaqPage from "./Routes/Pages/FaqPage";
import ErrorPage from "./Routes/Pages/ErrorPage";
import ComingSoonPage from "./Routes/Pages/ComingSoonPage";
import BlogsPage from "./Routes/Blogs/BlogsPage";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import Contacts from "./Routes/ContactsPage";
import PricingPage from "./Routes/Pages/PricingPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/our-services" element={<ServicePgae />} />
          <Route path="/our-services/services-details" element={<ServiceDetailsPage />} />
          <Route path="/our-team" element={<TeamPage />} />
          <Route path="/our-team/our-team-details" element={<TeamDetailsPage />} />
          <Route path="/recent-work" element={<WorksPage />} />
          <Route path="/recent-work/recent-work-details" element={<WorkDetailsPage />} />
          <Route path="/testimonial" element={<FeedBackPage />} />
          <Route path="/pricing-plan" element={<PricingPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/error-404" element={<ErrorPage />} />
          <Route path="/coming-soon" element={<ComingSoonPage />} />
          <Route path="/our-blogs" element={<BlogsPage />} />
          <Route path="/our-blogs/blog-details" element={<BlogDetailsPage />} />
          <Route path="/contact-us" element={<Contacts />} />
          <Route path="/get-a-quote" element={<Contacts />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

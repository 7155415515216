import React from 'react'
import Layout from '../Components/Layout'
import Banner from '../Components/Banner'
import Benifites from '../Components/Benifites'
import Abouts from '../Components/Abouts'
import Services from '../Components/Services'
import Works from '../Components/Works'
import Teams from '../Components/Teams'
import Feedbacks from '../Components/Feedbacks'
import PricingTable from '../Components/PricingTable'
import FAQs from '../Components/FAQs'
import Blogs from '../Components/Blogs'
import useDocumentTitle from '../Hooks/PageTitle'

const Home = () => {
  useDocumentTitle("TRoo SEO Agency - React Js Template")
  return (
    <Layout>
        <Banner/>
        <Benifites/>
        <Abouts/>
        <Services/>
        <Works/>
        <Teams/>
        <Feedbacks/>
        <PricingTable/>
        <FAQs/>
        <Blogs/>
    </Layout>
  )
}

export default Home
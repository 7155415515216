import React from 'react'
import { WorkData } from '../Data/WorkData'
import { Link, createSearchParams } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'

const Works2 = () => {
  return (
    <div className='py-12 lg:py-24'>
        <div className='main_container1'>
        <div className="grid sm:grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          {WorkData.map((e, i) => (
            <Link className="relative group rounded overflow-hidden" key={i} to={`/recent-work/recent-work-details?${createSearchParams({id : e.id})}`}>
              <div className="relative before:content-[''] before:absolute before:w-full before:h-full group-hover:before:bg-gradient-to-r from-troo-gad1 to-troo-gad2 before:top-0 before:left-0 before:opacity-40 before:rounded">
                <img className="w-full" src={e.img} alt={e.img} />
              </div>
              <div className="absolute text-troo-white bg-troo-deepblack py-10 px-5 left-0 bottom-0 text-left w-[80%] translate-y-[40px] opacity-0 group-hover:opacity-100 group-hover:translate-y-0 duration-500 ">
                <h3 className="mb-5">{e.name}</h3>
                <p>{e.para}</p>
                <div className="absolute transition-all duration-500 block scale-0 group-hover:scale-100  top-[-15px] right-[-15px]">
                <div className="flex group-hover:delay-100 items-center justify-center w-12 h-12  bg-troo-primary text-troo-white rounded-[50%] group-hover:transition-all ">
                  <FaChevronRight />
                </div>
              </div>
              </div>
            </Link>
          ))}
        </div>
        </div>
    </div>
  )
}

export default Works2
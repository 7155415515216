import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import FAQAccordian2 from '../../Components/FAQAccordian2'
import useDocumentTitle from '../../Hooks/PageTitle'

const FaqPage = () => {
  useDocumentTitle("TRoo SEO Agency | FAQs")
  return (
    <Layout>
      <BannerGlobal />
      <FAQAccordian2/>
    </Layout>
  )
}

export default FaqPage
import React from "react";
import { PricingData } from "../Data/PricingData";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";

const PricingTable2 = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 gap-y-20 lg:grid-cols-3">
          {PricingData.map((e, i) => (
            <div className="relative bg-troo-fadblue rounded shadow-sm p-8 group">
              <div className="before:content-[''] before:absolute before:top-0 before:left-0 before:bg-price-bg before:bg-no-repeat before:bg-cover before:w-full before:h-full bg-troo-deepblack group-hover:bg-gradient-to-r from-troo-gad1 to-troo-gad2  py-6 px-8 flex justify-center items-center text-troo-white gap-1 rounded mb-7 mt-[-70px] relative">
                <div className="text-4xl font-Montserrat font-bold ">
                  {e.price}
                </div>
                <p> / per Month</p>
              </div>
              <h3 className="my-2">{e.name}</h3>
              <p className="mb-4">{e.sub}</p>
              <div className="p-5 bg-troo-white flex rounded flex-col gap-4">
                {e.highlights.map((ele, ind) => (
                  <p className={ele.className} key={i}>
                    <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                    {ele.heading}
                  </p>
                ))}
                <Link className="btn1 border-troo-deepblack my-3 hover:bg-gradient-to-r from-troo-gad1 to-troo-gad2 hover:text-troo-white">
                  Choose This Plan
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingTable2;

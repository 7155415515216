import React from 'react'
import { TeamData } from '../Data/TeamData'
import { Link, createSearchParams } from 'react-router-dom'

const Teams2 = () => {
  return (
    <div className='py-12 lg:py-24'>
        <div className='main_container1'>
        <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-4">
          {TeamData.map((e, i) => (
           <Link
           className="relative group rounded overflow-hidden shadow-sm"
           key={i}
           to={`/our-team/our-team-details?${createSearchParams({
             id: e.id,
           })}`}
         >
           <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-gradient-to-r before:from-troo-gad1 before:to-troo-gad2 before:top-0 before:left-0 before:opacity-40 before:rounded before:transform before:translate-y-full before:transition-all before:ease-in-out before:duration-500 before:z-10 group-hover:before:translate-y-0">
             <img
               className="w-full "
               src={e.img}
               alt={e.img}
             />
           </div>
           <div className="bg-troo-white z-20 flex flex-col gap-3 justify-center items-center absolute bottom-0 w-full pl-12 py-6 left-0 right-0">
             <h4 className="group-hover:text-troo-gad1">{e.name}</h4>
             <p>{e.sub}</p>
           </div>
           <div className="absolute z-20 left-0 p-3 bottom-0 bg-troo-deepblack group-hover:bg-gradient-to-b from-troo-gad1 to-troo-gad2 ">
             {e.social.map((a, i) => (
               <div
                 className="hover:bg-troo-primary p-2 hover:text-troo-white text-troo-white rounded"
                 key={i}
               >
                 {a.icon}
               </div>
             ))}
           </div>
         </Link>
          ))}
        </div>
        </div>
    </div>
  )
}

export default Teams2
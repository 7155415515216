import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Benifites from '../Components/Benifites'
import Abouts from '../Components/Abouts'
import Feedbacks from '../Components/Feedbacks'
import Teams from '../Components/Teams'
import FAQAccordian from '../Components/FAQAccordian'
import useDocumentTitle from '../Hooks/PageTitle'

const AboutPage = () => {
  useDocumentTitle("TRoo SEO Agency | About us")
  return (
   <Layout>
    <BannerGlobal/>
    <Benifites/>
    <Abouts/>
    <Feedbacks/>
    <Teams/>
    <FAQAccordian/>
   </Layout>
  )
}

export default AboutPage
import blog1 from "../images/blog1.png";
import blog2 from "../images/blog2.png";
import blog3 from "../images/blog3.png";
import blog4 from "../images/blog4.png";
import blog5 from "../images/blog5.png";
import blog6 from "../images/blog6.png";
import blog7 from "../images/blog7.png";
import blog8 from "../images/blog8.png";
import blog9 from "../images/blog9.png";

import { BsPersonPlus, BsTag } from "react-icons/bs";

export const BlogData = [
  {
    id: 1,
    date: "16 March",
    year: "2023",
    name: "Business Growing Policy-SEO Most Popular System To Grow Site.",
    img: blog1,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 2,
    date: "16 jun",
    year: "2023",
    name: "How to create, optimize and promote an ebook",
    img: blog2,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 3,
    date: "16 March",
    year: "2023",
    name: "Google updates Bard to improve math, logic responses.",
    img: blog3,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 4,
    date: "16 March",
    year: "2023",
    name: "Hilariously terrible BuzzFeed travel articles were AI-assisted.",
    img: blog4,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 5,
    date: "16 jun",
    year: "2023",
    name: "Search marketing: Evolving roles, responsibilities, challenges.",
    img: blog5,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 6,
    date: "16 March",
    year: "2023",
    name: "How to write a content marketing mission statement.",
    img: blog6,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 7,
    date: "16 March",
    year: "2023",
    name: "How to build a values-based agency that drives results.",
    img: blog7,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 8,
    date: "16 jun",
    year: "2023",
    name: "2023 Salary & Career guide: How much search marketers make.",
    img: blog8,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
  {
    id: 9,
    date: "16 March",
    year: "2023",
    name: "New SEO study reveals the biggest investments of 2023.",
    img: blog9,
    pname: "Johnson Doe",
    dept: "Marketing",
    dptIcon: <BsTag />,
    pIcon: <BsPersonPlus />,
  },
];

import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Feedback2 from '../../Components/Feedback2'
import useDocumentTitle from '../../Hooks/PageTitle'

const FeedBackPage = () => {
  useDocumentTitle("TRoo SEO Agency | Clients Feedbacks")
  return (
    <Layout>
      <BannerGlobal />
      <Feedback2/>
    </Layout>
  )
}

export default FeedBackPage
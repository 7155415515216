import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Services2 from '../../Components/Services2'
import Benifites from '../../Components/Benifites'
import FAQAccordian from '../../Components/FAQAccordian'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServicePgae = () => {
  useDocumentTitle("TRoo SEO Agency | Services")
  return (
    <Layout>
    <BannerGlobal/>
    <Services2/>
    <Benifites/>
    <FAQAccordian/>
   </Layout>
  )
}

export default ServicePgae
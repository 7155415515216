import React from "react";
import Accordian from "./Accordian";
import { FaqData } from "../Data/FaqData";
import { Link } from "react-router-dom";

const FAQAccordian = () => {
  return (
 <div className="bg-troo-fadblue before:content-[''] z-[1]  before:absolute before:top-0 before:left-0 before:bg-faq-bg before:bg-no-repeat before:bg-cover before:bg-center before:w-full before:h-full relative ">
       <div className="main_container1  py-12 lg:py-24">
      <div className="flex flex-col lg:flex-row justify-between gap-7 lg:items-end mb-10">
        <div>
          <h4 className="mb-5 pt-7 relative before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-primary after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-primary">
            Our Agency Benefits
          </h4>
          <h2>Let’s Work Together</h2>
        </div>
        <p className="lg:max-w-[500px] xl:max-w-[600px]">
          Contrary to popular belief, Lorem Ipsum is not simply random text. It
          has roots in a piece of classical Latin literature from Lorem Ipsum is
          not simply random text. It hasroots 45 BC, making it over 2000 years
          old.
        </p>
      </div>
      <div className="lg:flex gap-6 py-10">
        <div className="flex flex-col gap-5 items-start">
          {FaqData.slice(0, 6).map((data, i) => {
            return <Accordian key={i} title={data.name} desc={data.para} />;
          })}
        </div>
        <div className="flex flex-col gap-5 items-start">
          {FaqData.slice(6, 12).map((data, i) => {
            return <Accordian key={i} title={data.name} desc={data.para} />;
          })}
        </div>
      </div>
      <div className="flex flex-col gap-5 items-center">
        <h5>Have you more question? Contact us</h5>
        <div>
          <Link to="/faq" className="btn1 border border-troo-deepblack">
            View All FAQ’s
          </Link>
        </div>
      </div>
    </div>
 </div>
  );
};

export default FAQAccordian;

import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import WorkDetails from "../../Components/WorkDetails";
import Highlights from "../../Components/Highlights";
import useDocumentTitle from "../../Hooks/PageTitle";

const WorkDetailsPage = () => {
  useDocumentTitle("TRoo SEO Agency | CaseStudy Details")
  return (
    <Layout>
      <BannerGlobal />
      <WorkDetails/>
      <div className="mb-[300px]">
      <Highlights/>
      </div>
    </Layout>
  );
};

export default WorkDetailsPage;

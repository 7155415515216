import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import logo2 from "../../images/Logo2.svg";
import useDocumentTitle from "../../Hooks/PageTitle";

const ComingSoonPage = () => {
  useDocumentTitle("TRoo SEO Agency | ComingSoon")
  return (
    <div className="bg-coming-bg w-full bg-no-repeat">
      <div className="main_container2 py-5">
        <div
          className="flex items-center gap-1 justify-end
      "
        >
          <p>We Are on :</p>
          <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
            <FaFacebookF />
          </Link>
          <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
            <FaInstagram />
          </Link>
          <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
            <FaTwitter />
          </Link>
          <Link className="hover:bg-troo-primary p-2 hover:text-troo-white rounded">
            <FaLinkedinIn />
          </Link>
        </div>
      </div>
      <div className="main_container1 grid grid-cols-1 lg:grid-cols-2 gap-10 py-16">
        <div className="flex items-center">
          <div className="flex flex-col gap-3 w-full">
            <div >
              <img  src={logo2} alt={logo2} />
            </div>
            <h4 className="mb-5 pt-7 relative before:content-[''] before:absolute before:w-8 before:h-[2px] before:left-0 before:top-2 before:bg-troo-primary after:content-[''] after:absolute after:w-8 after:h-[2px] after:left-3 after:top-0 after:bg-troo-primary">
              We Are Coming Soon
            </h4>
            <h2>Our Website Is Under Construction</h2>
            <p>
              We are working hard to give you a better experience with our new
              impressive site.
              <span className="text-troo-gad1">
                Stay connected, Stay updated!
              </span>
            </p>
            <h5>Notify Me </h5>
            <div className="flex flex-wrap relative w-full items-stretch  mb-14 ">
              <input
                className="w-[90%] py-[15px] bg-troo-fadblue rounded px-5 block"
                type="email"
                placeholder="Enter your Email"
              />
              <Link className="btn1 text-troo-white bg-gradient-to-r from-troo-gad1 to-troo-gad2 absolute right-0">
                Submit Now!
              </Link>
            </div>
            <h3 className="font-medium">
              For more inquiry:
              <span className="text-troo-gad1 font-bold">
                contact@troothems.com
              </span>
            </h3>
            <h3 className="font-medium">
              Call Us any Time:{" "}
              <span className="text-troo-gad1 font-bold">+44 132 456 7890</span>
            </h3>
          </div>
        </div>
        <div className="lg:ml-24 shadow p-6 bg-troo-white">
          <div className="text-center mb-8">
            <h3>Contact With Us</h3>
            <p>Lorem ipsum doller sit it is simply dumy text.</p>
          </div>
          <form className=" w-full">
            <div className="flex flex-col gap-y-5">
              <div className="w-full">
                <p className="mb-3">First Name</p>
                <input
                  type="text"
                  placeholder="First Name"
                  className="p-4 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                />
              </div>
              <div>
                <p className="mb-3">Last Name</p>
                <input
                  type="text"
                  placeholder="Last Name"
                  className="p-4 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                />
              </div>
              <div>
                <p className="mb-3">Enter Your Email</p>
                <input
                  type="email"
                  placeholder="Email"
                  className="p-4 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                />
              </div>
              <div>
                <p className="mb-3">Enter Your Phone</p>
                <input
                  type="text"
                  placeholder="Enter Your Phone"
                  className="p-4 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                />
              </div>

              <div className="w-full">
                <p className="mb-3">Write Comment</p>
                <textarea
                  placeholder="White something to me"
                  className="p-5 bg-troo-fadblue focus:outline-none focus-visible:ring-1 rounded w-full"
                  rows={4}
                />
              </div>
              <div>
                <Link className="btn1 bg-troo-gad1 text-troo-white  ">
                  Submit Now
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DetailsImg from "../images/serviceDetailsImg.png";
import { ServiceData } from "../Data/ServiceData";
import { BsArrowRight, BsCheckLg } from "react-icons/bs";
import servicedetails2 from "../images/serviceDetails2.png";
import icon1 from "../images/SdetailsIcon1.svg";
import icon2 from "../images/SdetailsIcon2.svg";
import { FaqData } from "../Data/FaqData";
import Accordian from "./Accordian";
import detailsSideImg from "../images/serviceDtlsSideImg.png";
import { Link } from "react-router-dom";

const ServiceDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");

  useEffect(() => {
    const filData = ServiceData.find((data) => data?.id == id);
    setHeading(filData?.name);
    setPicture(filData?.img2);
  }, [id]);
  return (
    <div className=" w-full py-12 lg:py-24 bg-troo-fadblue ">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[70%] flex flex-col items-start gap-5">
            <img
              className="w-full max-h-[600px]"
              src={picture || DetailsImg}
              alt="DetailsImg"
            />
            <h2>{heading || "Something About India"}</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary.
            </p>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>

            <h4 className="bg-troo-bluefade border-l-4 border-troo-gad1 p-4 bg-troo-white">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </h4>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly.
            </p>
            <div className="sm:flex justify-between gap-5 w-full">
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Accommondatio
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Breakfast
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Flights
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Porter & Personal Guide
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Tranportation / Car
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Typical Souvenir
                </p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Insurance
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-gradient-to-r from-troo-gad1 to-troo-gad2 rounded-[50%] text-troo-white " />
                  Lunch
                </p>
              </div>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </p>
            <h3>We create some things your success future</h3>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-5">
              <img
                className="w-full"
                src={servicedetails2}
                alt={servicedetails2}
              />
              <div className="flex flex-col gap-6 ml-12">
                <div className="bg-troo-white pl-14 py-5 pr-5 relative ">
                  <h5 className="mb-5 font-Montserrat">SEO Consultant</h5>
                  <p>
                    Lorem ipsum dolor sit amet, and consecte tur adipiscing
                    ipsum dolor sit amet elit.
                  </p>
                  <div className="p-3 bg-gradient-to-r from-troo-gad1 to-troo-gad2 absolute top-[30px] left-[-40px] rounded">
                    <img src={icon1} alt="icon1" />
                  </div>
                </div>
                <div className="bg-troo-white pl-14 py-5 pr-5 relative ">
                  <h5 className="mb-5 font-Montserrat">Creative Solutions</h5>
                  <p>
                    Lorem ipsum dolor sit amet, and consecte tur adipiscing
                    ipsum dolor sit amet elit.
                  </p>
                  <div className="p-3 bg-gradient-to-r from-troo-gad1 to-troo-gad2 absolute top-[30px] left-[-40px] rounded">
                    <img src={icon2} alt="icon2" />
                  </div>
                </div>
              </div>
            </div>

            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text.
            </p>
            <div className="flex flex-col gap-4 items-start">
              {FaqData.slice(0, 3).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} />;
              })}
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%]  ">

           <div className="flex flex-col items-start gap-6 bg-troo-white p-8 mb-10">
           <h4 className="relative text-troo-gad1 pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-gad1">
                Service Categories
              </h4>
              <div className="flex flex-col gap-5 w-full">
                {ServiceData.map((e, i) => (
                  <p className="hover:text-troo-white rounded p-3 bg-troo-fadblue flex justify-between items-center hover:bg-gradient-to-r from-troo-gad1 to-troo-gad2 w-full">
                    {e.name} <BsArrowRight />
                  </p>
                ))}
              </div>
           </div>
              <div className="text-troo-white rounded items-center bg-gradient-to-r from-troo-gad1 to-troo-gad2 flex flex-col gap-5">
                <h2 className="mx-auto text-center pt-6">Do You Have Technical Issue</h2>
                <div>
                <Link className="btn1 bg-troo-primary text-troo-white ">
                  Get A Quote
                </Link>
                </div>
                <img src={detailsSideImg} alt="sideimg" />
              </div>
      
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;

import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Works2 from '../../Components/Works2'
import FAQAccordian from '../../Components/FAQAccordian'
import useDocumentTitle from '../../Hooks/PageTitle'

const WorksPage = () => {
  useDocumentTitle("TRoo SEO Agency | CaseStudy")
  return (
    <Layout>
      <BannerGlobal />
      <Works2/>
      <FAQAccordian/>
    </Layout>
  )
}

export default WorksPage
import React from "react";
import { ServiceData } from "../Data/ServiceData";
import { Link, createSearchParams } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const Services2 = () => {
  return (
    <div className="bg-troo-fadblue py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 ">
          {ServiceData.map((e, i) => (
            <Link
              className="bg-troo-white px-5 py-8 rounded benifiteCard group shadow-md"
              key={i}
              to={`/our-services/services-details?${createSearchParams({id : e.id})}`}
            >
              <div className="flex flex-col gap-5">
                <div className="mb-5">
                  <img src={e.img} alt={e.img} />
                </div>
                <h4>{e.name}</h4>
                <p>{e.para}</p>
              </div>
              <div className="absolute top-5 right-5">
                <img src={e.icon} alt={e.icon} />
              </div>
              <div className="absolute transition-all duration-500 block scale-0 group-hover:scale-100  bottom-[-20px] right-10">
                <div className="flex group-hover:delay-100 items-center justify-center w-12 h-12  bg-troo-primary text-troo-white rounded-[50%] group-hover:transition-all ">
                  <FaChevronRight />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services2;
